.custome-select-box-class .css-t3ipsp-control {
  box-shadow: none !important;
}

.custome-select-box-class .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.custome-select-box-class .css-ggsyiz-control {
  box-shadow: none !important;
}

.custome-select-box-class .css-1dimb5e-singleValue {
  color: #fff !important;
  font-size: 12px;
  font-family: Poppins;
}

.custome-select-box-class .css-166bipr-Input {
  color: #fff !important;
}
