.input-label-div-input-div .MuiTextField-root {
  width: 100% !important;
  border: none !important;
}

.input-label-div-input-div .MuiInputBase-input {
  color: #fff !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.date-time-picker-custome {
  cursor: pointer !important;
}

.date-time-picker-custome .MuiFormControl-root {
  width: 100% !important;
  background-color: #1b243a !important;
  border-radius: 4px !important;
  /* height: 40px !important; */
  max-height: 40px !important;
  /* align-items: center !important; */
  justify-content: space-around;
}

.date-time-picker-custome .MuiOutlinedInput-input {
  padding: 9.5px 14px;
}

.MuiDateCalendar-root .MuiSvgIcon-root {
  fill: #fff !important;
}

.MuiButtonBase-root .MuiSvgIcon-root {
  fill: #fff !important;
}

.MuiDayCalendar-header .MuiDayCalendar-weekDayLabel {
  color: #fff !important;
}

.MuiDayCalendar-monthContainer .MuiDayCalendar-weekContainer .MuiPickersDay-root {
  color: #fff !important;
}

.MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClockSection-item {
  background-color: #1b243a !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.Mui-selected {
  border: 1px solid #fff !important;
  color: #1b243a !important;
  background-color: #1b243a !important;
}

.MuiDayCalendar-weekContainer .Mui-disabled {
  opacity: 0.3 !important;
}

.MuiPickersDay-root .Mui-selected {
  color: #1b243a !important;
}

.MuiPaper-root.MuiPaper-elevation {
  margin-top: 2px !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: none !important;
  top: unset !important;
  left: unset !important;
  height: 300px !important;
  overflow-y: scroll;
  background-color: #1b243a !important;
  color: #fff !important;
  margin-top: 2px !important;
}

.MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  display: none !important;
}
