.pareto-loader {
  width: 80px;
  aspect-ratio: 1;
  position: relative;
  animation: l5-0 2s infinite linear;
}

.pareto-loader:before {
  content: '';
  position: absolute;
  inset: 0;
  transform: translate(20px) rotate(0);
  animation: l5-1 0.5s infinite;
}

@keyframes l5-0 {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes l5-1 {
  100% {
    transform: translate(30px) rotate(0.25turn);
  }
}
