.tooltip-container {
  background: #1b243a;
  padding: 10px;
}
.jvectormap-tip {
  border: unset;
  padding: 0px;
  overflow: hidden;
  border-radius: 10px;
}
