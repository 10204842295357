.dropdown-continer-component {
  background: #1b243a;
  border-radius: 8px;
  width: 100%;
  /* margin-bottom: 15px; */
}

.dropdown-continer-component-placeholder {
  color: white;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
  word-wrap: break-word;
  margin: 0px;
}

.dropdown-continer-component-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 4px 10px;
}

.dropdown-continer-component-column-body {
  display: table-column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0px 10px;
}

.css-i5wq9m-control {
  box-shadow: none !important;
  outline: none !important;
}

.css-1nmdiq5-menu {
  background-color: #1b243a !important;
}

.css-13cymwt-control:visited:focus-visible {
  background-color: #1b243a !important;
  border: none !important;
}

.dropdown-continer-component-placeholder .css-1fdsijx-ValueContainer {
  padding: 8px 0px !important;
}

.css-lvhtuy-MenuList {
  scrollbar-color: #10182d #1b253a;
}

.filter-section-dropdown-two .css-15lsz6c-indicatorContainer {
  padding: 0px !important;
}

/* .css-lvhtuy-MenuList::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.css-lvhtuy-MenuList::-webkit-scrollbar-track-piece {
  background-color: #1b243a !important;
  color: black !important;
}

.css-lvhtuy-MenuList::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #0a4c95;
} */

/* .css-1fdsijx-ValueContainer {
  padding: 0px !important;
} */

/* .css-1l1ybae-control {
  font-size: 14px !important;
}

.country-section-continer-component .country-list-table-container::-webkit-scrollbar {
  display: none;
}

.country-section-continer-component .country-list-table-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
