.model-main-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #959db3 !important;
}

.contactus-title {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #ffff !important;
}

.input-label-div-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.dashboard-page-main-container-right-side-contact-form-textarea {
  padding: 10px 10px;
  border-radius: 4.87px;
  gap: 10px;
  background-color: #1b243a;
  color: #ffffff;
  border: none;
  width: 100%;
  height: 98px;
}

.dashboard-page-main-container-right-side-contact-form-textarea:focus-visible {
  border: none !important;
  outline: none;
}

.contact-form-submit-btn {
  border: none;
  width: Hug(93px);
  height: Hug(44px);
  padding: 10px 25px 10px 25px;
  border-radius: 4px;
  gap: 15px;
  background-color: #1b243a;
  color: #fff;
}

.contact-form-submit-btn:hover {
  border: none !important;
  background-color: #1b243ac9 !important;
}

.input-label-div-error-msg {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #d83a3a;
}

.input-label-div-input-div {
  border: none !important;
}

.contact-us-model-backdrop {
  /* background-color: #3b5698; */
  pointer-events: none !important;
}

body.modal-open .App {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}

body.modal-open .toast-hot-container {
  -webkit-filter: blur(0px) !important;
  -moz-filter: blur(0px) !important;
  -o-filter: blur(0px) !important;
  -ms-filter: blur(0px) !important;
  filter: blur(0px) !important;
}

.contact-us-model .model-header {
  background-color: #1b243a;
  padding: 20px 0px !important;
  color: #fff !important;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.contact-us-model .model-header .model-header-action-wrap {
  position: absolute;
  right: 20px;
}

.contact-us-model .model-header .model-header-action-wrap .model-header-action-icon {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.contact-form-btn-box {
  text-align: right;
}

.types-meeting-btns {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px 50px 50px;
}

.meet-options-btn {
  border-radius: 4.87px;
  border: none;
  background-color: #1b243a;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1b243a;
  height: 50px;
}

.meet-options-btn-zoom {
  border-radius: 4.87px;
  border: none;
  background-color: #1b243a;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1b243a;
  height: 50px;
}

.meet-options-btn-Hangouts {
  border-radius: 4.87px;
  border: none;
  background-color: #1b243a;
  width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1b243a;
  height: 50px;
}

.meet-options-btn-zoom-icon {
  width: 80px;
  height: 80px;
}

.meet-options-btn-Hangouts-icon {
  width: 30px;
  height: 30px;
}

.meet-options-selected-btn {
  border: 1px solid #8c94aa !important;
}

.modal-body-content {
  background-color: #111724 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
