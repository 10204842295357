.model-main-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #959db3 !important;
}

.contactus-title {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #ffff !important;
}

.input-label-div-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.input-label-div-input {
  padding: 10px 10px;
  /* border-radius: 5px; */
  gap: 10px;
  background-color: #1b243a;
  color: #ffffff;
  border: none;
  width: 100%;
}

.input-label-div-error-msg {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #d83a3a;
}

.input-label-div-input-div {
  border: none !important;
}

.contact-us-model-backdrop {
  /* background-color: #3b5698; */
  pointer-events: none !important;
}

body.modal-open .App {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
}

body.modal-open .toast-hot-container {
  -webkit-filter: blur(0px) !important;
  -moz-filter: blur(0px) !important;
  -o-filter: blur(0px) !important;
  -ms-filter: blur(0px) !important;
  filter: blur(0px) !important;
}

.contact-us-model .model-header {
  background-color: #1b243a;
  padding: 20px 0px !important;
  color: #fff !important;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.contact-us-model .model-header .model-header-action-wrap {
  position: absolute;
  right: 20px;
}

.contact-us-model .model-header .model-header-action-wrap .model-header-action-icon {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.contact-form-btn-box {
  text-align: right;
}

.modal-body-content {
  background-color: #111724 !important;
}

.contactus-title {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #ffff !important;
}

.contact-us-model .model-header {
  background-color: #1b243a !important;
  padding: 20px 0px !important;
  color: #fff !important;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header {
  justify-content: center;
  text-align: center;
  border: none !important;
  padding: 0.5rem 0.5rem !important;
}
